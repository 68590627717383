const skills = [
  "HTML",
  "CSS",
  "Bootstrap",
  "TailwindCSS",
  "JavaScript",
  "jQuery",
  "React",
  "PHP",
  "Laravel",
  "MySQL",
  "WordPress",
  "Unbounce",
];
const tools = [
  "Git",
  "GitHub",
  "Docker",
  "DBeaver",
  "Photoshop",
  "Illustrator",
  "InDesign",
  "Figma",
  "Sketch",
];

const About = () => {
  return (
    <div className="md:container mx-auto px-5 md:px-10 3xl:px-5 pt-5 xl:pt-10 pb-12">
      <div className="flex items-center justify-between gap-14 flex-col xl:flex-row">
        <div className="xl:w-1/2 leading-8 tracking-wide">
          <p>
            With 9+ years of experience, my expertise covers from independent
            projects to team collaboration. Strong dedication with up-to-date
            technologies, I ensure{" "}
            <span className="underline underline-offset-2 decoration-orange-400 italic">
              uncompromised
            </span>{" "}
            quality.
          </p>
          <h3 className="font-bold mt-8 xl:mt-4 mb-2 uppercase">What I Do?</h3>
          <div className="">
            <p>
              <strong className="font-medium italic">- Front-end:</strong>{" "}
              Crafting responsive, pixel-perfect and SEO-friendly websites with
              cross-browser compatibility, accessibility and W3C-validation.
            </p>
            <p className="mt-4">
              <strong className="font-medium italic">- Back-end:</strong>{" "}
              Utilizing performance efficient, clean coding practices to build
              robust, scalable and maintainable applications.
            </p>
          </div>
        </div>
        <div className="xl:w-44 flex-shrink-0 flex items-center gap-5 flex-col -order-1 xl:-order-none">
          <img
            className="w-44 ring-4 ring-orange-400 rounded-full"
            src="img/profile_photo2.png"
            alt="Muhammad Russell"
          />
        </div>
        <div className="xl:w-1/2 self-start tracking-wide text-gray-700">
          <div className="">
            <h3 className="font-bold mb-4">SKILLS:</h3>
            <div className="flex flex-wrap gap-3">
              {skills.map((skill, index) => (
                <span
                  key={index}
                  className="py-1 px-2 rounded-md border shadow-sm bg-slate-100"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>
          <div className="mt-10">
            <h3 className="font-bold mb-4">TOOLS:</h3>
            <div className="flex flex-wrap gap-3">
              {tools.map((skill, index) => (
                <span
                  key={index}
                  className="py-1 px-2 rounded-md border shadow-sm bg-slate-100"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
