import { useState } from "react";
import { NavLink } from "react-router-dom";

const Nav = ({ hello = false }) => {
  const [menu, setMenu] = useState(false);

  const toggleMenu = () => {
    setMenu(menu ? false : true);
  };

  return (
    <nav>
      <div
        className={`md:container m-auto flex flex-col md:flex-row px-5 md:px-10 3xl:px-5 py-4 gap-5 md:gap-9 lg:gap-10 border-y mb-10 select-none tracking-wider uppercase text-sm font-bold text-gray-500/90 hover:[&>a]:text-gray-600 [&>a]:transition-colors [&>a]:duration-500 [&>a.active]:!text-orange-400 ${
          !menu && "max-md:[&>a]:hidden"
        } max-md:[&>a.active]:inline-block`}
      >
        <NavLink
          to="/about"
          onClick={() => toggleMenu()}
          className={hello && "active"}
        >
          About Me
        </NavLink>
        <NavLink to="/career" onClick={() => toggleMenu()}>
          Career
        </NavLink>
        <NavLink to="/projects">Projects </NavLink>
        <NavLink to="/testimonials" onClick={() => toggleMenu()}>
          Testimonials
        </NavLink>
        <NavLink to="/certificates" onClick={() => toggleMenu()}>
          Certifications
        </NavLink>
        <a href="Resume_Muhammad_Russell.pdf">Resume</a>
        <span
          onClick={() => toggleMenu()}
          className="absolute right-5 w-8 inline-flex md:hidden flex-col gap-1"
        >
          <span className="w-full h-1 bg-slate-700"></span>
          <span className="w-full h-1 bg-slate-700"></span>
          <span className="w-full h-1 bg-slate-700"></span>
        </span>
      </div>
    </nav>
  );
};

export default Nav;
